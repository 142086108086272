import React from "react";
import "./Services.css";

const services = [
  {
    id: "production",
    title: "Creative Production",
    content:
      "Our team of world-class producers and composers is dedicated to bringing your artistic vision to life. Whether you need a bespoke beat or a fully produced song, we specialize in crafting music that reflects your unique style and identity.",
    image: "/imgs/services/mixing-echo.jpg",
  },
  {
    id: "recording",
    title: "Recording",
    content:
      "At The Lab Studios, we pride ourselves on being a home to some of the most influential names in the music industry. Our studio offers the perfect environment for capturing your sound with precision and clarity.",
    image: "/imgs/services/mastering-echo.jpg",
  },
  {
    id: "mixandmaster",
    title: "Mix & Master",
    content:
      "Elevate your music to a professional level with our comprehensive mixing and mastering services. We ensure a dynamic, polished sound that resonates with audiences worldwide.",
    image: "/imgs/services/dolby.jpg",
  },
  {
    id: "surroundSoundMixing",
    title: "5.1 Surround Sound Mixing",
    content:
      "Experience immersive, three-dimensional soundscapes with Dolby Atmos mixing. Perfect for music, film, and multimedia projects, it transforms how audiences experience your work.",
    image: "/imgs/services/surround-sound.jpg",
  },
  {
    id: "videographers",
    title: "Videographers/ Photographers",
    content:
      "Our rooms and facility are perfect for podcasts, music videos, listening parties, social media content and for capturing your special moments.This will help promote your content and/or music before dropping.",
    image: "/imgs/services/videographer.jpg",
  },
];

const Services = () => {
  return (
    <div className="services-container">
       <h2 className="services-title">Our Services</h2>
       <div className="services-divider"></div>
      {/* Pre-accordion Intro */}
      <div className="services-intro">
        <h2 className="intro-title">Experience Professional Audio Production</h2>
        <p className="intro-description">
          At The Lab Studios, we offer an extensive range of services to cater
          to every aspect of your audio production needs. From the creativity of
          custom production to the precision of mixing, mastering, and immersive
          audio experiences like Dolby Atmos, we ensure your music or project
          reaches its fullest potential.
        </p>
      </div>

      {/* Alternating Services Layout */}
      {services.map((service, index) => (
        <div
          key={service.id}
          className={`service-item ${
            index % 2 === 0 ? "service-item-left" : "service-item-right"
          }`}
        >
          <div className="service-text">
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.content}</p>
          </div>
          <div className="service-image">
            <img src={service.image} alt={service.title} />
          </div>
        </div>
      ))}

      {/* Book a Session Button */}
      <div className="book-session-container">
        <a href="/booking" className="book-session-button">
          Book a Session
        </a>
      </div>
    </div>
  );
};

export default Services;