import React from "react";
import "./Footer.css";

function Footer() {
  return (
   <footer class="simple-footer">
  <div class="footer-logo-container">
  <img
            src={`${process.env.PUBLIC_URL}/assets/thelab-logo.png`}
            alt="The Lab Studios Logo"
            className="footer-logo"
          />
  </div>
  <nav class="footer-nav">
    <a href="/about">About Us</a>
    <a href="/projects">Projects</a>
    <a href="/policies">Policies</a>
    <a href="/contact">Contact</a>
  </nav>
  <div class="footer-bottom">
    <p>© 2025 Echosistema / The Lab – Designed By Eugenio Espinoza</p>
  </div>
</footer>
  );
}

export default Footer;