import React from 'react';
import Policies from '../containers/Policies/PoliciesSection';
import Footer from '../containers/Footer/Footer';

const PoliciesPage = () => {
  return (
    <div>
      <Policies />
      <Footer />
      
    </div>
  );
};
  
export default PoliciesPage;