import React from 'react';
import { FaUserTie, FaMusic, FaTools, FaGuitar } from 'react-icons/fa'; // Import icons
import './AddonsSection.css';

const AddonsSection = () => {
  const addons = [
    {
      title: 'Senior Engineer',
      content:
        'Senior Engineers with years of experience. Many have acquired a unique set of skills that are only earned by the countless hours they have put in behind the boards. Our senior engineers are mix engineers, vocal arrangers, and editors. These engineers have worked alongside megastars, independent musicians, and know exactly what is required from both.',
      icon: <FaUserTie />, // Add corresponding icon
    },
    {
      title: 'Vocal Arranging',
      content:
        'Vocal arrangement can be described as the way you sing your song. Not necessarily the words that you sing but the way you sing them. A certain line may sound better when sung in your falsetto voice as opposed to your chest voice. We offer vocal arrangement services provided by our senior engineers at a premium hourly rate.',
      icon: <FaMusic />, // Add corresponding icon
    },
    {
      title: 'Tuning & Editing',
      content:
        'Vocal Tuning is an art and requires a skilled experienced professional to craft a vocal performance to perfection. We use tools like Melodyne and autotune to ensure that vocals are in perfect harmony and rhythm with one another.',
      icon: <FaTools />, // Add corresponding icon
    },
    {
      title: 'Session Musician',
      content:
        'Session musicians are expert studio players who are hired on a short-term basis to record backing tracks for recording artists. Session musicians write and perform music in your recording session as you desire.',
      icon: <FaGuitar />, // Add corresponding icon
    },
  ];

  const pricing = [
    {
      plan: 'Test Tube',
      price: '$750',
      details: [
        '10 Hours Pre Paid',
        'Includes Asst. Engineer',
        '$25 Sound Engineer',
        'Must RSVP 48 Hrs in Advance',
        '25% Off any service by The Echo Team',
      ],
    },
    {
      plan: 'Potentiometer',
      price: '$1,500',
      details: [
        '25 Hours Pre Paid',
        'Includes Asst. Engineer',
        '$25 Sound Engineer',
        'Must RSVP 48 Hrs in Advance',
        '25% Off any service by The Echo Team',
      ],
    },
    {
      plan: 'Incubator',
      price: '$2,500',
      details: [
        '50 Hours Pre Paid',
        'Includes Asst. Engineer',
        '$25 Sound Engineer',
        'Must RSVP 48 Hrs in Advance',
        '25% Off any service by The Echo Team',
      ],
    },
  ];

  return (
    <div className="addons-section">
      {/* Studio Add-ons Section */}
      <div className="addons-content">
        <h2 className="addons-title">Studio Add-ons</h2>
        <p className="addons-description">
          Contact us in advance about our in-house team and packages to include with your session.
        </p>
        <div className="addons-grid">
          {addons.map((addon, index) => (
            <div key={index} className="addon-card">
              <span className="addon-icon">{addon.icon}</span> {/* Render the icon */}
              <h3 className="addon-title">{addon.title}</h3>
              <p className="addon-description">{addon.content}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Pricing Section */}
      <div className="pricing-section">
        <h2 className="pricing-title">Pre-Paid Hours</h2>
        <p className="pricing-subtitle">The Lab Studios</p>
        <div className="pricing-grid">
          {pricing.map((plan, index) => (
            <div key={index} className="pricing-card">
              <h3 className="plan-title">{plan.plan}</h3>
              <p className="plan-price">{plan.price}</p>
              <p className="plan-duration">Per Month</p>
              <ul className="plan-details">
                {plan.details.map((detail, idx) => (
                  <li key={idx}>{detail}</li>
                ))}
              </ul>
              <button className="buy-now-button">Buy Now</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddonsSection;