import React from "react";
import studiobanner from "../../imgs/echo6.jpg";
import "./BookingSection.css";

const BookingSection = () => {
  return (
    <div className="booking-section">
      {/* Banner Section */}
      <div className="booking-banner">
        <div className="banner-content">
          <h1 className="banner-title">Book a Session</h1>
        </div>
      </div>

      {/* Booking Main Section */}
      <div className="booking-main">
        {/* Left Column: Contact Form */}
        <div className="booking-left">
          <h2 className="form-title">The Lab Studio Rental Request</h2>
          <form
            action="https://api.web3forms.com/submit"
            method="POST"
            className="booking-form"
          >
            <input type="hidden" name="access_key" value="your-access-key" />

            <label htmlFor="artistName">Artist Name</label>
            <input
              type="text"
              id="artistName"
              name="artistName"
              placeholder="Enter artist name"
              required
            />

            <label htmlFor="sessionDate">Session Date</label>
            <input type="date" id="sessionDate" name="sessionDate" required />

            <label htmlFor="arrivalTime">Arrival Time</label>
            <input type="time" id="arrivalTime" name="arrivalTime" required />

            <label>Space Needed</label>
            <div className="radio-group">
              <label>
                <input type="radio" name="spaceNeeded" value="Studio A" required />
                Studio A
              </label>
              <label>
                <input type="radio" name="spaceNeeded" value="Studio B" />
                Studio B
              </label>
              <label>
                <input type="radio" name="spaceNeeded" value="Studio C" />
                Studio C
              </label>
              <label>
                <input type="radio" name="spaceNeeded" value="Exclusive" />
                Exclusive ( All Rooms )
              </label>
            </div>

            <label htmlFor="staff">Staff</label>
            <select id="staff" name="staff" required>
              <option value="">Select</option>
              <option value="Sound Engineer">Sound Engineer</option>
              <option value="Vocal Arranger">Asst. Engineer ( I'll Bring Mine )</option>
              <option value="Session Musician">Full Staff</option>
            </select>

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email"
              required
            />

            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter phone number"
              required
            />

            <label htmlFor="specialRequest">Special Request</label>
            <textarea
              id="specialRequest"
              name="specialRequest"
              placeholder="Enter special request"
              required
            ></textarea>

            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>

        {/* Right Column: Banner and Details */}
        <div className="booking-right">
          <img
            src={studiobanner}
            alt="Studio Add-ons"
            className="booking-image"
          />
          <div className="booking-details">
            <h3>Studio Add-ons</h3>
            <p>
              Enhance your session with professional engineers, vocal arrangers,
              and talented session musicians.
            </p>
            <a href="/addons" className="view-more-button">
              View More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSection;