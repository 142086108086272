import React, { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import "./ContactSection.css";

function ContactSection() {
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    message: "",
  });

  const [formStatus, setFormStatus] = useState("");
  const [showBanner, setShowBanner] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormStatus("Submitting...");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        access_key: "0aea328e-896e-4cf6-9df5-ba32d07a6a5c", // Replace with your Web3Forms access key
        ...formData,
      }),
    });

    const result = await response.json();

    if (result.success) {
      setFormStatus("Message sent successfully!");
      setFormData({
        email: "",
        phone: "",
        message: "",
      });
    } else {
      setFormStatus("Failed to send message. Please try again.");
    }

    setShowBanner(true);
    setTimeout(() => setShowBanner(false), 3000); // Auto-hide the banner after 3 seconds
  };

  return (
    <div className="contact-page">
      {showBanner && <div className="form-status-banner">{formStatus}</div>}

      <div className="contact-container">
        {/* Left Section - Where to Find Us */}
        <div className="where-to-find-us">
          <h2 className="section-title">WHERE TO FIND US?</h2>
          <div className="location">
            <FaMapMarkerAlt className="icon1" />
            <div className="location-details">
              <p>2574 Tequesta Ln, Miami Fl, 33133</p>
              <p>info@thelabstudiosmiami.com</p>
              <p>(954) 890-0701</p>
            </div>
          </div>
        </div>

        {/* Right Section - Drop Us a Line */}
        <div className="drop-us-a-line">
          <h2 className="section-title">CONTACT US</h2>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder=" "
                required
              />
              <label htmlFor="email">Email Address</label>
            </div>
            <div className="form-group">
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder=" "
                required
              />
              <label htmlFor="phone">Phone Number</label>
            </div>
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder=" "
                required
              ></textarea>
              <label htmlFor="message">Message</label>
            </div>
            <button type="submit" className="send-message-btn">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;