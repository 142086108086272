import React from 'react';
import Booking from '../containers/Bookings/BookingSection'
import Footer from '../containers/Footer/Footer';
  
const BookingPage = () => {
  return (
    <div>
      <Booking/>
      <Footer />
    </div>
  );
};
  
export default BookingPage;
