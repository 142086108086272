import React from "react";
import { FaSyncAlt, FaDollarSign, FaFileAlt } from "react-icons/fa";
import "./PoliciesSection.css";

function PoliciesPage() {
  return (
    <div className="policies-page">
      <div className="policies-container">
        {/* Main Policy Section */}
        <div className="main-policy-item  main-policy">
          <h2 className="policy-title">The Lab Policies</h2>
          <p className="main-policy-description">
          Please arrive on time for your session as punctuality is essential to ensure a smooth and uninterrupted experience for all clients. 
          We do not push sessions back for tardiness, as it disrupts the schedule for other bookings. If you arrive late to your session, you will still be charged for the full-time scheduled, 
          regardless of the time remaining in your booking. This policy ensures fairness to all clients and helps us maintain efficiency in our operations.
          <br /><br />
            Additionally, we allow a maximum of three guests per session in our facility. 
            This limit is in place to ensure a professional and distraction-free environment for both you and our staff. 
            Please plan accordingly and inform your guests in advance to adhere to this policy. 
            We appreciate your cooperation in helping us create a productive and enjoyable experience for everyone.
          </p>
        </div>

        {/* Policy Sections with Icons */}
        <div className="policy-item">
          <FaSyncAlt className="policy-icon" />
          <h3 className="policy-subtitle">Cancel or Rescheduling</h3>
          <p className="policy-description">
            Cancellations made after the 24-hour deadline will result in a
            cancellation fee of 50% of the total amount of the scheduled studio
            time or a forfeit of deposit/prepayment. We do not offer refunds on
            cancellations made before the 24-hour deadline. You are only allowed
            to reschedule your session one time. Rescheduled sessions after the
            initial change will result in the forfeit of deposit. We do not
            offer refunds on rescheduled sessions made before the 24-hour
            deadline.
          </p>
        </div>

        <div className="policy-item">
          <FaDollarSign className="policy-icon" />
          <h3 className="policy-subtitle">Deposits</h3>
          <p className="policy-description">
            A deposit of 50% is required to reserve your scheduled session. The
            remaining balance is due upon finalizing your session. Payees must
            provide a valid photo ID along with the credit card used for payment
            upon arrival. The ID must match the name on the card used for
            deposit/balance payments. If the person who made the payment is not
            in attendance or does not have an ID, your deposit will be forfeited
            unless an alternative method of payment is provided.
          </p>
        </div>

        <div className="policy-item">
          <FaDollarSign className="policy-icon" />
          <h3 className="policy-subtitle">Payments & Fees</h3>
          <p className="policy-description">
            We accept major label PO’S, cash, wire transfers, and credit
            card/debit cards. There is a late fee of 10% when you fail to pay
            prior to your session. Your session files won't be released until
            payment is processed.
          </p>
        </div>

        <div className="policy-item">
          <FaFileAlt className="policy-icon" />
          <h3 className="policy-subtitle">Your Files</h3>
          <p className="policy-description">
            We are not responsible for your session files. Please request your
            files to be sent during your booked time or bring an external hard
            drive to the session. A $95 storage fee is required for future
            session/song transfers beyond the booked time.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PoliciesPage;