import React from "react";
import "./ProjectsSection.css";

// Dynamically import all images from the folder
const importAll = (r) => r.keys().map(r);
const covers = importAll(require.context("../../imgs/projects", false, /\.(png|jpe?g|svg)$/));

// Helper function to generate non-overlapping positions
const generateNonOverlappingPositions = (count, containerWidth, containerHeight, size) => {
  const positions = [];
  const maxAttempts = 100; // Limit the number of attempts to find a non-overlapping position

  for (let i = 0; i < count; i++) {
    let attempts = 0;
    let position;

    do {
      const top = Math.random() * (containerHeight - size);
      const left = Math.random() * (containerWidth - size);
      position = { top, left };

      // Check for overlap
      const isOverlapping = positions.some(
        (pos) =>
          Math.abs(pos.top - top) < size && Math.abs(pos.left - left) < size
      );

      if (!isOverlapping) {
        positions.push(position);
        break;
      }

      attempts++;
    } while (attempts < maxAttempts);
  }

  return positions;
};

const ProjectsSection = () => {
  // Generate positions for the cover arts
  const positions = generateNonOverlappingPositions(
    covers.length, // Number of covers
    100, // Container width percentage
    100, // Container height percentage
    12 // Minimum size percentage of the cover-art
  );

  return (
    <div className="projects-section">
      <div className="content-container">
        <h1 className="headline">Discover Our Latest Projects</h1>
        <p className="subheadline">
        From energetic rhythms to heartfelt ballads, explore the latest releases and get inspired by the sound of innovation.
        </p>
      </div>
      <div className="cover-art-container">
        {covers.map((cover, index) => (
          <img
            key={index}
            src={cover}
            alt={`Cover ${index + 1}`}
            className="cover-art"
            style={{
              top: `${positions[index].top}%`,
              left: `${positions[index].left}%`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectsSection;