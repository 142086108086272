import React from 'react';
import Addons from '../containers/Addons/AddonsSection';
import Footer from '../containers/Footer/Footer';
  
const AddonsPage = () => {
  return (
    <div>
      <Addons />
      <Footer />
    </div>
  );
};
  
export default AddonsPage;