import React from "react";

import { FaCompactDisc, FaMicrophone, FaVolumeUp, FaHeadphones } from "react-icons/fa";
import "./BioPreviewSection.css";
import thelab from "../../imgs/the-lab1.jpg";

function BioPreviewSection() {
  const features = [
    {
      title: "Creative Production",
      description:
        "Have our team of producers and composers craft you exactly the beat and the song your looking for customized to you or come and listen to our catalogue of ideas, beats and music.",
      icon: <FaCompactDisc />,
    },
    {
      title: "Recording",
      description:
        "As a recording studio The Lab has welcomed many of the most important names in the industry including: Daddy Yankee, Tego Calderon, Don Omar, Wisin & Yandel, Ricky Martin, Nicky Jam, Gente de Zona, Peso Pluma, and many others.",
      icon: <FaMicrophone />,
    },
    {
      title: "Mix & Master",
      description:
        "From music recordings, post-editing, and vocal productions to sound design and music and film mixing, our studio offers it all for everyone from upcoming rising stars to vocal creatives who’ve hit the global stage.",
      icon: <FaVolumeUp />,
    },
    {
      title: "Dolby Atmos",
      description:
        "Atmos has reinvented how entertainment is created and experienced, allowing creatives to place each sound exactly where they want it to go, for a more realistic and immersive audio experience.",
      icon: <FaHeadphones />,
    },
  ];

  return (
    <div className="product-card">
      <div className="image-section">
        <img
          src={thelab} // Replace with your image URL
          alt="Product"
        />
      </div>
      <div className="details-section">
        <h2 className="product-title">WE'RE BRINGING YOUR CREATIVE VISION TO LIFE</h2>
        <p className="product-description">
          From THE LAB STUDIOS in Miami and San Juan, Echo and his elite team transform ideas into chart-topping music, 
          collaborating with renowned artists to shape the sound of today and tomorrow.
        </p>
        <div className="features-container">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <div className="feature-icon">{feature.icon}</div>
              <div>
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BioPreviewSection;