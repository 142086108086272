import React, { useState, useEffect } from "react";
import "./StudiosSection.css";

// Import images
import StudioA from "../../imgs/StudioA.jpg";
import StudioB from "../../imgs/StudioB.jpg";
import StudioC from "../../imgs/StudioC.jpg";

const studios = [
  {
    name: "Studio A",
    type: "Studios Specs",
    description: "A top-notch studio designed for professional mixing sessions.",
    specs: [
      "SOLID STATE LOGIC ORIGIN CONSOLE",
      "AUGSPURGER DUO 8” WITH DUAL 12” SUBWOOFERS",
      "EMPIRICAL LABS EL8-XS DISTRESSORS PAIR W/BRITISH",
      "MODE & ST LINK",
      "RUPERT NEVE SHELFORD CHANNEL STRIP",
      "AVALON DESIGNS VT- 737 CHANNEL STRIP",
      "AVID PRO TOOLS HDX TB3 CHASSIS WITH HDX CORE",
      "ANTELOPE AUDIO ORION 32 HD GEN 3 AUDIO INTERFACE",
      "CRANE SONGS HEDD 192KHZ AD/DA CONVERTER",
      "C ELECTRONICS FINALIZER",
      "SHADOW HILLS VANDERGRAPH ST COMPRESSOR",
      "SSL 4000 BUS COMPRESSOR",
      "STAM AUDIO 312C 500 SERIES TUBE PREAMPS",
    ],
    rate: "$95/hour",
    minimumHours: "3 hours minimum",
    engineerNotIncluded: true,
    image: StudioA,
  },
  {
    name: "Studio B",
    type: "Studios Specs",
    description: "Perfect for capturing vocals and acoustic instruments.",
    specs: [
      "SOLID STATE LOGIC AWS 948 DELTA",
      "FOCAL TRIO 11 3-WAY MONITORS",
      "RUPERT NEVE SHELFORD CHANNEL STRIP",
      "AVALON DESIGNS VT- 737 CHANNEL STRIP",
      "TUBE TECH ME-1CA TUBE CHANNEL STRIP",
      "AVID PRO TOOLS HDX TB3 CHASSIS WITH HDX CORE",
      "UA QUAD TB2 SATELLITE DSP PROCESSOR UNIT",
      "ANTELOPE AUDIO ORION 32 HD GEN 3 AUDIO INTERFACE",
      "SSL ALPHA LINK MADI INTERFACE",
      "CRANE SONGS HEDD 192KHZ AD/DA CONVERTER",
      "TC ELECTRONICS FINALIZER",
      "SHADOW HILLS VANDERGRAPH ST COMPRESSOR",
      "SSL 4000 BUS COMPRESSOR",
    ],
    rate: "$100/hour",
    minimumHours: "3 hours minimum",
    engineerNotIncluded: true,
    image: StudioB,
  },
  {
    name: "Studio C",
    type: "Studios Specs",
    description: "Equipped with state-of-the-art mastering equipment.",
    specs: ["7.4.1", "FOCAFOCAL ATMOS RIG", "KALI AUDIO SUB", "APOLLO X6 INTERFACE"],
    rate: "$85/hour",
    minimumHours: "2 hours minimum",
    engineerNotIncluded: true,
    image: StudioC,
  },
];

function StudiosSection() {


useEffect(() => {
  const adjustHeight = () => {
    const allDetails = document.querySelectorAll(".studio-details");
    let maxHeight = 0;

    allDetails.forEach((details) => {
      maxHeight = Math.max(maxHeight, details.offsetHeight);
    });

    allDetails.forEach((details) => {
      details.style.height = `${maxHeight}px`;
    });
  };

  adjustHeight();
  window.addEventListener("resize", adjustHeight);

  return () => {
    window.removeEventListener("resize", adjustHeight);
  };
}, []);


  const [activeStudio, setActiveStudio] = useState(0);

  return (
    <section className="studios-section">
    <div className="studios-title-divider"></div>
      <div class="studios-container">
      <div className="studios-header">
        <h2 className="studios-title">Explore Our Studios</h2>
        <p className="studios-subtitle">
          Experience professional-grade facilities tailored to meet your creative needs.
        </p>
      </div>
      <div className="studios-layout">
        {/* Left: Studio List */}
        <div className="studio-list">
          {studios.map((studio, index) => (
            <button
              key={index}
              className={`studio-item ${activeStudio === index ? "active" : ""}`}
              onClick={() => setActiveStudio(index)}
            >
              {studio.name}
            </button>
          ))}
        </div>

        {/* Middle: Studio Image */}
        <div className="studio-image">
          <img src={studios[activeStudio].image} alt={studios[activeStudio].name} />
        </div>

        {/* Right: Studio Details */}
        <div className="studio-details">
          <h3 className="studio-name">{studios[activeStudio].name}</h3>
          <p className="studio-description">{studios[activeStudio].description}</p>
          <ul className="studio-specs">
            {studios[activeStudio].specs.map((spec, index) => (
              <li key={index}>{spec}</li>
            ))}
          </ul>
          <p className="studio-rate">{studios[activeStudio].rate}</p>
          <p className="studio-minimum">{studios[activeStudio].minimumHours}</p>
          {studios[activeStudio].engineerNotIncluded && (
            <p className="studio-engineer">Sound Engineer Not Included</p>
          )}
          <button className="book-now-button">Book Now</button>
        </div>
      </div>
      </div>
    </section>
  );
}

export default StudiosSection;